
import "@/assets/sass/template.scss";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import store from "@/store";
import moment from "moment";
import TableTab from "@/components/kt-datatable/TableTab.vue";
import ApiService from "@/core/services/ApiService";
import PaginationUtill from "../util/PaginationUtill";

export default defineComponent({
  name: "DataTable",

  components: {
    TableTab,
  },
  setup() {
    let accountId = store.getters.AccountId;
    const sumaccuralvalue = ref(0.0);
    const accuralperiod = ref(0.0);
    const accuraldays = ref(0.0);
    const SiteDetails = ref();
    const tableValues = ref([]);
    const fromDate = ref(new Date());
    const toDate = ref(new Date());
    const table = ref(false);
    const loading = ref<boolean>(false);
    interface Tableheader {
      name: string;
      key: string;
      sortable: boolean;
    }

    let TableHeaders: Array<Tableheader> = PaginationUtill.AccuralReportgas;
    const OriginalTableHeaders = ref<Array<Tableheader>>();

    OriginalTableHeaders.value = TableHeaders.map((a) => ({
      name: a.name,
      key: a.key,
      sortable: true,
    }));
    const colnames = ref();
    const disableDates = (time) => {
      const currentDate = new Date();
      const selectedDate = new Date(
        time.getFullYear(),
        time.getMonth(),
        time.getDate()
      );
      return selectedDate > currentDate;
    };
    const series = reactive([{}]);
    const labels = ref([""]);
    const eboptions = {
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
          height: 350,
          type: "line",
        },
        stroke: {
          show: true,
          lineCap: "butt",
          colors: undefined,
          width: 3,
          dashArray: 0,
          curve: "smooth",
        },
        title: {
          text: "",
        },
        dataLabels: {
          enabled: false,
          //enabledOnSeries: [1],
        },
        xaxis: {
          type: "datetime",
          labels: {
            datetimeUTC: false,
            formatter: function (val, timestamp) {
              return moment(new Date(timestamp)).format("MMM YYYY");
            },
            style: {
              colors: "#9899ac",
            },
          },

          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: [
          {
            title: {
              text: "Consumption (kWh) and CO₂ (kgCO₂e)",
            },
          },
          {
            opposite: true,
            title: {
              text: " Cost (£) ",
            },
          },
        ],
      },
    };
    onMounted(() => {
      // table.value = true;
      setCurrentPageBreadcrumbs(" Accural Report - Electricity", ["NA"]);
    });

    async function getContent() {
      const fromdateval: any = fromDate.value;
      const toDateval: any = toDate.value;
      let domainName = store.getters.DomainName.toLowerCase();
      loading.value = true;
      const accountInfo: any = {
        accountid: accountId,
        fdate: moment(fromdateval).format("YYYY-MM-DD 00:00:00"),
        tdate: moment(toDateval).format("YYYY-MM-DD 00:00:00"),
        DomainName: domainName,
      };

      ApiService.post("api/accuralreport/ele_accuralreport", accountInfo)
        .then((resp) => {
          tableValues.value = resp.data.accuralReportEle.map((a) => a);
          colnames.value = TableHeaders.map((a) => a.key);
          table.value = true;
          loading.value = false;
          sumaccuralvalue.value = resp.data.summaryEle.accuralvalue;
          accuralperiod.value = resp.data.summaryEle.accuralperiod;
          accuraldays.value = resp.data.summaryEle.accuraldays;
        })
        .catch((a) => {
          loading.value = false;
          console.log("Api Log Error", a);
        });
    }

    function openAssetRemoval() {
      table.value = true;
    }
    const pageheader = "Accural Report - Electricity";
    return {
      OriginalTableHeaders,
      pageheader,
      labels,
      series,
      SiteDetails,
      TableHeaders,
      sumaccuralvalue,
      accuralperiod,
      accuraldays,
      colnames,
      fromDate,
      toDate,
      openAssetRemoval,
      table,
      tableValues,
      eboptions,
      loading,
      getContent,
      disableDates,
    };
  },
});
